import React, { useState, useRef, useCallback } from 'react';
import {Container, Form} from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import CustomField from '../form/CustomField';
import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';
import md5 from 'md5';
import { Cookies } from "react-cookie";
import { BrowserView, MobileView } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";

const cookies = new Cookies();

const Offre = (props) => {
    const { register, handleSubmit } = useForm({validateCriteriaMode: "all"});
    const submitRef = useRef(null);
    const [ offers, setOffers ] = useState([]);

    if (offers.length === 0) {
        fetch('/file/offres.json',
            {
                method : 'GET',
            })
            .then(response => response.json())
            .then(json =>
            {
                setOffers(json)
            })
    }

    //--------------------
    //form validation
    //--------------------
    const onSubmit = data => {
        submitRef.current.setAttribute("disabled","disabled")

        let formData = new FormData();

        data.user = props.user

        offers.map((offer) => {
            var inputs = document.getElementsByName(offer.name)
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].checked) {
                    data[offer.name] = inputs[i].value
                }
            }
        })
        formData.append('form',JSON.stringify(data));

        fetch(process.env.REACT_APP_API_URL + '/api/submitBoosterCode.php',
            {
                method : 'POST',
                body : formData
            })
            .then(response => response.json())
            .then(json =>
            {
                submitRef.current.removeAttribute("disabled")

                if(json.success === true){
                    window.scrollTo(0, 0)
                    props.callBack()
                }

            });
    };

    return(
        <Form onSubmit={handleSubmit(onSubmit)} className="offre-container" method="POST" noValidate>
            <div className="offre-wrapper">
                <div className="offre-title">
                    Nous avons négocié avec nos partenaires <span className="fc-red">les meilleures offres</span> ci-dessous,
                    merci de nous confirmer votre choix en cliquant <span className="no-wrap">'oui' :</span>
                </div>
                <div className="offre-choices row">
                    {offers.map((offer) =>
                        <div className="offre-item col-12 col-lg-6">
                            <div className="offre-item-inner">
                                <img src={offer.image} />
                                <div className="offre-content">
                                    <p dangerouslySetInnerHTML={{ __html:offer.text }}></p>
                                    <div className="radio">
                                        <label>
                                            Oui <input type="radio" name={offer.name} value="1" defaultChecked />
                                        </label>
                                        <label>
                                            Non <input type="radio" name={offer.name} value="0" />
                                        </label>
                                    </div>
                                    {offer.error && <div className="error">Veuillez faire un choix</div>}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="text-center">
                    <button ref={submitRef} type="submit" className="cta black cta-inscription">Je valide</button>
                </div>
            </div>
        </Form>
    )
}

const ParticipationForm = (props) => {

    const [show, setShow] = useState(false);
    const { register, handleSubmit, errors, setError, clearError } = useForm({validateCriteriaMode: "all"});
    const submitRef = useRef(null);
    const submitRef2 = useRef(null);
    const [date, setDate] = useState({ year: "", month: "", day: "" });
    const [civilityError, setCivilityError] = useState(false);
    const [birthdateError, setBirthdateError] = useState(false);
    const [cadeau, setCadeau] = useState(0);
    const [civilite, setCivilite] = useState(null);
    const [sponsor, setSponsor] = useState(false);

    setError(false)

    //--------------------
    //form validation
    //--------------------
    const onSubmit = data => {

        setCivilityError(false)
        setBirthdateError(false)

        // if (cadeau == null){
        //     setCadeau(0)
        // }
        if (civilite === null){
            setCivilityError(true)
            return;
        }
        if (date.year === '' || date.month === '' || date.day === ''){
            setBirthdateError(true)
            return;
        }

        submitRef.current.setAttribute("disabled","disabled")
        submitRef2.current.setAttribute("disabled","disabled")

        //envoi du formulaire
        let formData = new FormData();
        data.cadeau = cadeau
        data.civilite = civilite
        data.date_naissance = date.year + '-' + (date.month.length === 1 ? '0' : '') + (Number(date.month) + 1) + '-' + (date.day.length === 1 ? '0' : '') + date.day

        if (sponsor) {
            data.optin_offres = 1
        } else {
            data.optin_offres = 0
        }

        formData.append('form',JSON.stringify(data));

        fetch(process.env.REACT_APP_API_URL + '/api/submitInscriptionForm.php',
            {
                method : 'POST',
                body : formData
            })
            .then(response => response.json())
            .then(json =>
            {
                submitRef.current.removeAttribute("disabled")
                submitRef2.current.removeAttribute("disabled")

                if(json !== null) {
                    if (json.success === true){
                        window.scrollTo(0, 0)
                        props.callBack(json.user, data.email, data.nom, data.prenom, sponsor)
                    }else{
                        document.getElementById("inscription_global_error").innerHTML = json.error
                        setError(true)
                    }
                } else {
                    document.getElementById("inscription_global_error").innerHTML = "Une erreur est survenue"
                    setError(true)
                }
            });
    };


    return(
		<Form onSubmit={handleSubmit(onSubmit)} id="inscription_form" method="POST" noValidate>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-12">
                    <div className="cadeau_wrapper">
                        <label>Je choisis* :</label>
                        <select id="cadeau" name="cadeau" className="form-control" onChange={(e) => setCadeau(e.target.value)}>
                            <option value="0">La console</option>
                            <option value="1">Le trampoline</option>
                        </select>
                    </div>
                    <div className="civilite_wrapper">
                        <div className="civilite_inner">
                            Civilité* :
                            <div className="radio-toolbar">
                                <input id="madame" type="radio" className="civilite-radio" name="civilite" value="0" onChange={(e) => setCivilite(e.target.value)} />
                                <label htmlFor="madame">Madame</label>

                                <input id="monsieur" type="radio" className="civilite-radio" name="civilite" value="1" onChange={(e) => setCivilite(e.target.value)} />
                                <label htmlFor="monsieur">Monsieur</label>
                            </div>
                        </div>
                        {civilityError && <div className="error">Civilité incorrecte</div>}
                    </div>
                    <CustomField name="nom" placeholder="Nom*" register={ register({ required: { value : true, message : Helper.EmptyErrorMessages.nom }, pattern: { value : Pattern.alphaPattern, message : Helper.InvalidErrorMessages.nom} })} error={errors.nom && errors.nom.message}/>
                    <CustomField name="prenom" placeholder="Prénom*" register={register({ required: { value : true, message : Helper.EmptyErrorMessages.prenom }, pattern: { value : Pattern.alphaPattern, message : Helper.InvalidErrorMessages.prenom} })} error={errors.prenom && errors.prenom.message}/>
                    <CustomField name="email" placeholder="Adresse e-mail*"
                         register={
                             register({
                                 required : true,
                                 pattern : Pattern.emailPattern,
                                 validate : {
                                     domain : (value) => Helper.checkMailDomain(value)
                                 }})}
                         error={[
                             errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail ,
                             errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                             errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain
                         ]}/>
                </div>
                <div className="col-12 col-md-6 col-lg-12">
                    <div className="date_naissance_wrapper">
                        <div className="date_naissance_inner">
                            <label>Date de naissance*</label>
                            <div className="date_naissance_select">
                                <DayPicker
                                    defaultValue={""}
                                    year={date.year} // mandatory
                                    month={date.month} // mandatory
                                    endYearGiven // mandatory if end={} is given in YearPicker
                                    value={date.day} // mandatory
                                    onChange={(day) => {
                                        setDate((prev) => ({ ...prev, day }));
                                    }}
                                    id={"day"}
                                    classes={`dropdown`}
                                    optionClasses={"option"}
                                />
                                <MonthPicker
                                    defaultValue={""}
                                    numeric // to get months as numbers
                                    endYearGiven // mandatory if end={} is given in YearPicker
                                    year={date.year} // mandatory
                                    value={date.month} // mandatory
                                    onChange={(month) => {
                                        setDate((prev) => ({ ...prev, month }));
                                    }}
                                    id={"month"}
                                    classes={`dropdown`}
                                    optionClasses={"option"}
                                />
                                <YearPicker
                                    defaultValue={""}
                                    start={1900} // default is 1900
                                    end={2023} // default is current year
                                    reverse // default is ASCENDING
                                    value={date.year} // mandatory
                                    onChange={(year) => {
                                        // mandatory
                                        setDate((prev) => ({ ...prev, year }));
                                    }}
                                    id={"year"}
                                    classes={`dropdown`}
                                    optionClasses={"option"}
                                />
                            </div>
                        </div>
                        {birthdateError && <div className="error">Date de naissance incorrecte</div>}
                    </div>
                    <CustomField name="codepostal" placeholder="Code postal*" register={register({ required: { value : true, message : Helper.EmptyErrorMessages.codepostal } })} error={errors.codepostal && errors.codepostal.message}/>
                    <CustomField name="ville" placeholder="Ville*" register={register({ required: { value : true, message : Helper.EmptyErrorMessages.ville } })} error={errors.ville && errors.ville.message}/>
                    <div className="text-right mention">
                        *Champs obligatoires
                    </div>
                        <div className="optin_wrapper">
                        <div className="checkbox_wrapper">
                            <label htmlFor="optin_reglement" className="mention">
                                <input type="checkbox" name="optin_reglement" id="optin_reglement" value="1" ref={ register()}/>
                                J'accepte le <a href="/file/reglement.pdf" target="_blank">règlement</a> et la <a href="/file/privacy-policy.pdf" target="_blank">politique de confidentialité</a>
                            </label>
                            <div className="error text-center mention">{errors.optin_reglement && Helper.EmptyErrorMessages.optin_reglement}</div>
                        </div>
                    </div>
                    <div className="text-center mention">
                        <p>En continuant, je reconnais avoir lu la <a href="/file/privacy-policy.pdf" target="_blank">politique de protection des données</a>.</p>
                    </div>
                    <div className="text-center">
                        <button ref={submitRef} type="submit" className="cta cta-inscription" onClick={() => setSponsor(true)}>Je participe et j'accepte de recevoir les offres des partenaires</button>
                        {/*<p className="mention partenaires">Je souhaite jouer sans m'inscrire aux offres partenaires</p>*/}
                        <button ref={submitRef2} type="submit" className="cta cta-inscription cta-inscription" onClick={() => setSponsor(false)}>Je participe et je refuse de recevoir les offres des partenaires</button>
                    </div>
                    {errors && <div id="inscription_global_error" className="text-center mt-3 mt-lg-3 error"/>}
                </div>
            </div>
		</Form>
    )
}

class Home extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            account_created: false,
            track: false,
            lastname: '',
            firstname: '',
            email: '',
            optin: false,
            ip: '',
            user: null,
            code: '',
            utm_source: null,
            showFinish: false
        }

        this.showFinish = this.showFinish.bind(this)
    }

    showFinish() {
        this.setState({showFinish: true})
    }

    sendRegistrationTracking = (code, dotation, utm_source) => {
        let source = "direct";
        if (utm_source != null && utm_source != undefined && utm_source != ''){
            source = utm_source
        }

        let optin = 0;
        if (this.state.optin){
            optin = 1;
        }
        let formData = new FormData();
        formData.append('form', JSON.stringify({
            email: this.state.email,
            lastname: this.state.lastname,
            firstname: this.state.firstname,
            custom1: optin,
            custom2: code,
            custom3: dotation,
            custom4: source,
            ip: this.state.ip
        }));

        // fetch(process.env.REACT_APP_API_URL + '/api/trackRegister.php',
        //     {
        //         method : 'POST',
        //         body : formData
        //     })
        //     .then(response => response.json())
        //     .then(json =>
        //     {
        //     });
    };

    checkOpeStop = () => {
        if( ! ("REACT_APP_DATE_OPE_STOP" in process.env) )
            return false

        //let start_date_string = "05/06/2020".split("/");
        let stop_date_string = process.env.REACT_APP_DATE_OPE_STOP.split("/")
        let stop_date = new Date(stop_date_string[2],stop_date_string[1]-1,stop_date_string[0])
        let now_date = new Date()

        return (now_date >= stop_date)
    }

    async componentDidMount(){
        // const response = await fetch('https://geolocation-db.com/json/');
        // const data = await response.json();
        // this.setState({ ip: data.IPv4 })
        //
        // const { utm_source } = queryString.parse(this.props.location.search)
        // this.setState({utm_source: utm_source})
    }

	render(){

		return(
			<>
            <div className={`background ${this.state.account_created ? "show" : ""} `}></div>

            {!this.checkOpeStop() && !this.state.account_created &&
				<Container id="home">
                    <ParticipationForm callBack={(userId, email, lastname, firstname, sponsor) => {
                        this.setState({
                            account_created : true,
                            user: userId,
                            email: email,
                            lastname: lastname,
                            firstname: firstname,
                            showFinish: sponsor === true ? false : true
                        })
                    }}/>
                </Container>
            }
            {this.checkOpeStop() &&
            <Container id="home-end">
                <div className="title">Merci pour votre participation à notre jeu.<br/>Merci à tous pour votre participation.</div>
                <div className="subtitle">Le jeu est désormais terminé.<br/>Les grands gagnants seront tirés au sort dans les prochains jours.</div>
            </Container>
            }
            {!this.checkOpeStop() && this.state.account_created && this.state.showFinish === false &&
                <Container id="offres">
                    <Offre callBack={this.showFinish} user={this.state.user} />
                </Container>
            }
            {!this.checkOpeStop() && this.state.account_created && this.state.showFinish === true &&
                <Container id="home-end">
                    <div className="title">Merci pour votre participation à notre jeu.</div>
                </Container>
            }
			</>
		);
	}
}

export default Home;
