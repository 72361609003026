import React from 'react';
import {Container} from 'react-bootstrap';

const Footer = () => {

	return(
		<footer>
			<div>
				<a href="/file/reglement.pdf" target="_blank">Règlement</a>
				-
				<a href="/file/privacy-policy.pdf" target="_blank">Politique de confidentialité</a>
				-
				<a href="https://www.r-advertising.com/" target="_blank">Réalisation</a>
			</div>
			<div>
				<span>Visuels non contractuels</span>
			</div>
		</footer>
	);
};

export default Footer;
